import { Link } from 'react-router-dom';
import TypeWriterEffect from 'react-typewriter-effect';
import logo from '../assets/images/logo.webp';
import React from 'react';
import Slideshow from './Slideshow';
import Arrow from '../assets/images/Arrow.png';
import { motion } from 'framer-motion';

function Homepage() {

  return (
  <>
  <motion.div initial={{x: -window.innerWidth}} animate={{x: 0, transition: { duration: 0.6}}} exit={{x: -window.innerWidth, opactiy: 1, transition: { duration: 0.6}}}>
        <div>
            <div className='bg-[#161616]'>
                <Slideshow />
            </div>
            
            <div className='absolute top-0 left-0 w-2/3 lg:w-[600px] px-8 py-6'>
                <img className='w-full' src={logo} alt="hydroswing logo" />
            </div>

            <div className='absolute top-0 right-0'>
                <Link to='/projects' className=''>
                    <div className=' grid grid-rows-2 items-center p-4 w-[100px] md:w-[150px] h-[100px] md:h-[150px] bg-[#161616] text-white transition-colors duration-500 transform hover:bg-[#597DAF]'>
                        <p className='text-[12px] md:text-[20px] text-center md:pb-2 md:pt-2 max-sm:pb-2 align-center'>Our Works</p>
                        <img src={Arrow} alt='arrow' className='m-auto align-center w-1/2 md:w-full'/>
                    </div>
                </Link>
            </div>


            <div>
                <div className='uppercase text-white absolute bottom-0 left-0 p-4 md:p-20'>
                    <TypeWriterEffect 
                    textStyle={{ fontFamily: 'Barlow' }}
                    startDelay={400}
                    cursorColor="white"
                    text="Architecture In Motion"
                    typeSpeed={200}
                    eraseSpeed={10}/>
                </div>
            </div>
            
            
            <div className='md:flex'>
            <Link to='/projects' className='inline-flex py-10 px-4 md:p-20 h-full w-full  md:w-4/5 transition-color duration-500 bg-black hover:bg-[#597DAF]'>
                    <h2 className='text-white inline my-auto md:pr-2 max-sm:text-[32px] md:pb-4'>See Our Works</h2>
                        <div className=''>
                            <svg width="126" height="40" viewBox="0 0 126 40" fill="none" xmlns="http://www.w3.org/2000/svg" className='w-[132px] h-[40px] inline h-full scale-50 md:scale-80'>
                                <g clipPath="url(#clip0_390_1165)">
                                <path d="M10 20H210" stroke="white" strokeWidth="2"/>
                                </g>
                                <path d="M104 20H116M116 20L104.955 10M116 20L104.955 30" stroke="white" strokeWidth="2"/>
                                <defs>
                                <clipPath id="clip0_390_1165">
                                <rect width="94" height="20" fill="white" transform="translate(10 10)"/>
                                </clipPath>
                                </defs>
                            </svg>
                        </div>
                </Link>
                <div className=' bg-white md:w-1/5 md:float-right p-10 text-right'>
                    <nav className='my-auto'>
                        <ul className='block-flex py-4 text-display'>
                            <li className='hover:underline'><Link to='/about-us'>About Hydroswing</Link></li>
                            <li className='py-4 hover:underline'><Link to='/technical'>Technical</Link></li>
                            <li><Link to='/contact' className='hover:underline'> Contact Us</Link></li>
                        </ul>
                    </nav>
                    <p className='opacity-50 text-[12px] pt-8'>Hydroswing® 2023 all rights reserved</p>
                </div>
            </div>
        </div>
    </motion.div>
      </>
      );
}

export default Homepage;