import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import projects from './ProjectDetails';
import ProjectCarousel from './Carousel';

function ProjectPage() {
  const { projectId } = useParams();

  const [project, setProject] = useState(null);

  useEffect(() => {
    const selectedProject = projects.find((proj) => proj.location === projectId);
    setProject(selectedProject);
  }, [projectId]);

  if (!project) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <motion.div initial={{ y: window.innerHeight, opacity: 1 }} animate={{ y: 0, opacity: 1, transition: { duration: 0.6 } }} exit={{ y: window.innerHeight, opacity: 1, transition: { duration: 0.6 } }}>
        <div>
          <div className="md:inline-flex z-4!">
            <div className="absolute z-10 top-0 left-0 bg-[#161616] text-white p-6 transition-transform duration-500 transform hover:bg-[#597DAF]">
              <Link to="/projects">
                <h3 className="text-[16px] md:text-[24px]">All Works</h3>
                <svg
                  width="95"
                  height="40"
                  className="w-[50px] md:w-[95px] h-[20px] md:h-[40px]"
                  viewBox="0 0 95 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path d="M22 20H9.99995M9.99995 20L21.0454 10M9.99995 20L21.0454 30" stroke="white" strokeWidth="2" />
                  <g clipPath="url(#clip0_632_306)">
                    <path d="M22 20H222" stroke="white" strokeWidth="2" />
                  </g>
                  <defs>
                    <clipPath id="clip0_632_306">
                      <rect width="63" height="20" fill="white" transform="translate(22 10)" />
                    </clipPath>
                  </defs>
                </svg>
              </Link>
            </div>

            <div className="md:w-1/2 md:relative -z-10!">
              <div className='md:sticky md:top-0 w-full relative'>
                <img src={project.mainImage}  alt={` ${project.location} project`} className=" md:h-screen object-cover w-full" />
                <div className='absolute bottom-0 left-0 bg-black bg-opacity-20'>
                  <ul className='text-white font-display p-4'>
                  {project.references.architect && (
                    <li className='font-bold pb-2'>
                      Architect
                      <ul>
                        <li className='text-[12px]'>{project.references.architect}</li>
                      </ul>
                    </li>
                  )}
                  {project.references.facade && (
                    <li className='font-bold pb-2'>
                      Façade Designer
                      <ul>
                        <li className='text-[12px]'>{project.references.facade}</li>
                      </ul>
                    </li>
                  )}
                  {project.references.contractor && (
                    <li className='font-bold pb-2'>
                      Main Contractor
                      <ul>
                        <li className='text-[12px]'>{project.references.contractor}</li>
                      </ul>
                    </li>
                  )}
                </ul>
                </div>
              </div>
            </div>

            <div className="md:w-1/2 p-8">
              <div className="items-end justify-right">
                <div className="relative h-[50px] md:h-[100px]">
                  
                  <div className="w-[50px] md:w-[100px] absolute right-10">{project.type.animation}</div>
                </div>

                <div className="text-right p-4">
                  <Link to='/technical' className="underline text-display max-sm:text-[12px]">
                    Learn more about <span className="uppercase font-bold"> Hydraulic {project.type.name}?</span>
                  </Link>
                </div>
              </div>

              <div className="md:float-right">
                <h1 className="italic max-sm:py-4 text-[22px] md:text-[40px] border-b-2 border-black">
                  {project.location} <span className="text-[24px] md:text-[40px]"><br />{project.city}</span>
                </h1>
                <p className="md:w-1/2 py-8 max-sm:text-[12px]">{project.introText}</p>
                {project.additionalImages.map((image, index) => (
                  <div key={index}>
                    <img src={image} alt={`preview ${index} of ${project.location}`} />
                    <p className="md:w-1/2 py-8 max-sm:text-[12px]">
                      {project.viewMoreText[index]}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="pb-10 ">
            <h2 className='bg-black pt-10 md:pt-40 pb-4 md:pb-10 text-white max-sm:pl-8 md:pl-40 text-[20px] md:text-[40px]'>Completed Hydroswing Projects</h2>
            <div className='max-sm:pl-8 md:pl-40 py-4'>
              <ProjectCarousel projects={projects} currentProjectIndex={0} />
            </div>
            
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default ProjectPage;
