const MobImages = [

  require("../assets/images/Component4.webp"),
  require("../assets/images/cali-mob.webp"),
  require("../assets/images/cali-img-mob-2.webp"),
  require("../assets/images/mob-hero-2.webp")

    // add more images as needed
  ];
  
  export default MobImages;