import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

const ScrollToTopOnLinkClick = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return null;
};

const ProjectCarousel = ({ projects, currentProjectIndex }) => {
  return (
    <div>
      <ScrollToTopOnLinkClick />
      <div className="flex flex overflow-x-auto">
        {projects.map((project, index) => (
          <div key={index} className="w-auto px-0">
            <div className="bg-white">
              <div className="md:py-4 w-[150px] md:w-[320px] h-[100px] md:h-full">
                <h3 className="text-lg font-semibold text-sm">
                  <span className="font-bold">{project.location}</span> {project.city}
                </h3>
              </div>
              <Link to={`/projects/${project.location}`}>
                <div className="overflow-hidden relative w-[130px] md:w-[300px] h-[250px] md:h-[520px]">
                  <div style={{ position: 'absolute', bottom: '0', width: '100%', height: '100%' }}>
                    <img
                      src={project.mainImage}
                      alt={project.city}
                      className="w-full h-full object-cover transition-transform duration-300 transform-gpu filter grayscale hover:scale-110 hover:filter-none"
                    />
                  </div>
                </div>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProjectCarousel;
