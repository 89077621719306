import { Link } from 'react-router-dom';
import logo from '../assets/images/logo_black.webp';
import React, { useState } from 'react';
import { motion } from 'framer-motion';
import projects from './ProjectDetails';
import MainCarousel from './MainCarousel';
import { useSwipeable } from 'react-swipeable';

function Projects() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlers = useSwipeable({
    onSwipedLeft: nextButton,
    onSwipedRight: previousButton,
  });

  function nextButton() {
    const newIndex = currentIndex + 1;
    setCurrentIndex(newIndex > projects.length - 1 ? projects.length - 1 : newIndex);
  }

  function previousButton() {
    const newIndex = (currentIndex - 1 + projects.length) % projects.length;
    setCurrentIndex(newIndex);
  }

  return (
    <motion.div className='relative h-[100vh]' initial={{ x: window.innerWidth, y: 0 }} animate={{ x: 0, transition: { duration: 0.6 } }} exit={{ x: window.innerWidth, transition: { duration: 0.6 } }}>
      <nav className='relative h-[10vh] md:h-[15vh] p-0 m-0'>
        <Link to='/' className='flex w-1/2 h-full my-auto items-center flex'>
          <div className='absolute top-0 left-0 w-2/3 md:w-[25%] my-auto px-4 md:px-8 py-2 md:py-6'>
            <img className='w-full my-auto object-contain' src={logo} alt="hydroswing logo" />
          </div>
        </Link>
        {/* projects navigation */}
        <div className='absolute top-0 right-0 flex max-sm:pt-4 max-md:hidden'>
          <div className='inline bg-transparent p-4 md:p-10 cursor-pointer' onClick={previousButton} key="previousButton">
            <svg
              width="126"
              height="40"
              className={`w-[45px] md:w-[126px] h-[20px] md:h-[40px] ${currentIndex === 0 ? "opacity-25" : "opacity-1"} animate-arrow-hover`}
              viewBox="0 0 126 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_15_130)">
                <path d="M116 20L-84 20" stroke="#161616" strokeWidth="2" />
              </g>
              <path d="M22 20L9.99996 20M9.99996 20L21.0454 30M9.99996 20L21.0454 10" stroke="#161616" strokeWidth="2" />
              <defs>
                <clipPath id="clip0_15_130">
                  <rect width="94" height="20" fill="white" transform="translate(116 30) rotate(-180)" />
                </clipPath>
              </defs>
            </svg>
          </div>


          <div className='inline p-4 md:p-10 cursor-pointer' onClick={nextButton} key="nextButton">
            <svg width="126" height="40" className={` w-[45px] md:w-[126px] h-[20px] md:h-[40px] ${currentIndex === projects.length - 1 ? "opacity-25" : "opacity-1"} animate-arrow-hover`} viewBox="0 0 126 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_15_125)">
                <path d="M10 20H210" stroke="#161616" strokeWidth="2" />
              </g>
              <path d="M104 20H116M116 20L104.955 10M116 20L104.955 30" stroke="#161616" strokeWidth="2" />
              <defs>
                <clipPath id="clip0_15_125">
                  <rect width="94" height="20" fill="white" transform="translate(10 10)" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
      </nav>
      
      {/* bACKGROUND LINE */}
      <div className='w-full absolute top-[22%] md:top-[33%] h-[0.2%] bg-gray-400 -z-20'></div>

      <div className=' flex w-screen max-md:mb-[100px] overflow-x-hidden md:h-[85vh] md:overflow-y-hidden p-0 m-0' {...handlers} style={{ touchAction: 'pan-y' }}>
        <MainCarousel projects={projects} currentProjectIndex={currentIndex} />
      </div>

    </motion.div>
  );
}

export default Projects;
