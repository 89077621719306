import { Link } from 'react-router-dom';
import logo_black from '../assets/images/logo_black.webp';
import exit from '../assets/images/exit.svg';
import React, { useState } from 'react';


import { motion, AnimatePresence } from 'framer-motion';

import Family from './FamilyDetails';


function Technical() {


    const [activeIndex, setActiveIndex] = useState(0); // Initialize the active index state

  const handleItemClick = (index) => {
    setActiveIndex(index); // Set the clicked item as active
  };

    return (
        <>
        <motion.div initial={{x: window.innerWidth}} animate={{x: 0, transition: { duration: 0.6}}} exit={{x: window.innerWidth, transition: { duration: 0.6}}}>
            <div className='mx-auto'>
                <div className=' p-20'>
                        <Link to='/'>
                            <div className='absolute top-0 left-0 w-2/3 lg:w-[350px] px-8 py-6'>
                                <img className='w-full' src={logo_black} alt="hydroswing logo" />
                            </div>
                        </Link>
                    </div>
                    <div className='absolute top-0 right-0 w-[100px]'>
                        <Link to='/'>
                            <img src={exit} alt='exit button' className='w-full p-8' />
                        </Link>
                    </div>


                <div className='align-center text-center md:w-[60%] mx-auto'>
                    <h1 className='text-[40px] md:text-[60px] uppercase'>The Hydroswing Hydraulic Door/Wall Family</h1>
                </div>
                
                <div className='w-full flex pt-8'>
                    <nav className='mx-auto'>
                        <ul className=' grid grid-cols-3 mx-auto text-center gap-10 md:gap-60 uppercase max-sm:text-[12px] text-wrap'>
                        {Family.map((item, index) => (
                            <li key={index} className='mx-auto w-[80px] md:w-[120px] h-[150px] md:h-[250px] overflow-hidden'
                                onClick={() => handleItemClick(index)}>
                                <div className='h-[100px] md:h-[160px]'>
                                    {item.thumbnail}
                                    <span className={` ${index === activeIndex ? 'font-bold text-[#80A3D3] md:text-[22px]' : ''}`}>{item.name}</span>
                                </div>
                                <div className={`mx-auto w-full h-[40px] md:h-[90px] pt-2 `}>
                                    <svg  className={`mx-auto transition-all duration-300 transform ${index === activeIndex ? 'pt-2 md:pt-6' : ' translate-y-20'}`} viewBox="0 0 49 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M24.5 0L48.3157 24.75H0.684301L24.5 0Z" fill="#F2F2F2"/>
                                    </svg>    
                                </div>                            
                            </li>      ))}
                            </ul>
                            </nav>
                </div>


                <div className='pb-4 md:pb-20'>


                    <div className="bg-[#F2F2F2] mx-2 md:mx-12 md:py-20  rounded-lg">
                        <AnimatePresence>
                        {activeIndex !== null && (
                        <motion.div
                            key={activeIndex}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1}}
                            exit={{ opacity: 0}}
                            transition={{ duration: 0.6 }}
                            className='md:grid grid-cols-2 py-10 md:py-20'
                        >
                            <div className='md:py-16'>
                            {Family[activeIndex].image}
                            </div>
                            <div className="p-4 md:p-0 md:pr-20">
                            <h2 className="text-[18px] md:text-[40px] py-2 md:py-8">Hydraulic {Family[activeIndex].name}</h2>
                            {Family[activeIndex].text.map((text, index) => (
                                <p className="text-[12px] md:text-[16px] text-justify md:pr-20 pb-2" key={index}>{text}</p>
                            ))}
                            {Family[activeIndex].pdf && (
                                <div className='pt-8'>
                                <a href={Family[activeIndex].pdf} className='bg-black text-white p-4 !mt-8 transition-color duration-300 hover:bg-[#80A3D3]'>
                                    Generic Specifications
                                </a>
                                </div>
                            )}
                            </div>
                        </motion.div>
                        )}

                        </AnimatePresence>
                    </div>
                </div>

                <div>
                <Link to='/projects' className='inline-flex py-10 px-4 md:p-20 h-full w-full transition-color duration-500 bg-black hover:bg-[#597DAF]'>
                    <h2 className='text-white inline my-auto md:pr-2 max-sm:text-[32px] md:pb-4'>See Our Works</h2>
                        <div className=''>
                            <svg width="126" height="40" viewBox="0 0 126 40" fill="none" xmlns="http://www.w3.org/2000/svg" className='w-[132px] h-[40px] inline h-full scale-50 md:scale-80'>
                                <g clipPath="url(#clip0_390_1165)">
                                <path d="M10 20H210" stroke="white" strokeWidth="2"/>
                                </g>
                                <path d="M104 20H116M116 20L104.955 10M116 20L104.955 30" stroke="white" strokeWidth="2"/>
                                <defs>
                                <clipPath id="clip0_390_1165">
                                <rect width="94" height="20" fill="white" transform="translate(10 10)"/>
                                </clipPath>
                                </defs>
                            </svg>
                        </div>
                </Link>
                </div>
            </div>
        </motion.div>
         </>
    )
}

export default Technical;