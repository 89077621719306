
import './App.css';
import TransitionRoutes from './TransitionRoutes';


function App() {
  
  return (
    <>

    <TransitionRoutes />
    </>
  );
}

export default App;

