import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import Arrow from '../assets/images/down_arrow.png';

function MainCarousel({ projects, currentProjectIndex }) {

const [isHovered, setIsHovered] = useState(false);
    const containerStyle = {
        transform: `translateX(${currentProjectIndex * -240}px)`,
        transition: 'transform 0.5s ease-in-out',
      };
      

      const previousIndexStyle = {
        transform: `translateX(-40pc)`,
      };
    return (
       
            <div style={containerStyle} className='md:max-h-[85vh]'>
                <ul className='inline-flex my-auto md:h-[100%] w-full overflow-y-hidden min-w-full'>
                    <AnimatePresence>
                    {projects.map((project, index) => (
                    
                    <li key={project.id} className={`md:max-h-fit overflow-x-hidden ${index === (currentProjectIndex +1) ? "" : ""}`}> 
                        <motion.div
                        key={index}
                        animate={{ x: index === currentProjectIndex ? 0 : 20 }}
                        transition={{ duration: 0.5 }}
                        className={`  ${
                            currentProjectIndex === index
                            ? ' md:inline-flex md:w-[55pc] md:h-[100%] z-10  pl-10 '
                            : 'w-[240px] md:w-[15%]!  my-auto pt-12 md:pt-20'
                        }`}
                        style={
                            currentProjectIndex - index === 1
                              ? { ...containerStyle, ...previousIndexStyle }
                              : index >= currentProjectIndex + 5
                              ? { display: 'none' }
                              : {}
                          }>
                            <Link to={`/projects/${project.location}`} className={`  ${currentProjectIndex === index ? "w-[300px] md:w-[65%] max-h-fit" : "w-[100%]"}`} >
                                <div className='h-[15%] items-center my-auto'>
                                    <h1 className={`${
                                        currentProjectIndex === index
                                        ? "max-md:pb-2 leading-tight pt-10 no-underline! italic text-[12px] md:text-base"
                                        : "text-[12px] max-sm:pb-8"
                                    }`}>
                                        <span className={`font-bold ${
                                        currentProjectIndex === index ? "text-[16px] md:text-[30px]" : ""
                                        }`}>
                                        {project.location}
                                        </span>{" "}
                                        {project.city}
                                    </h1>
                                </div>

                                
                                <div className={` ${currentProjectIndex === index ? " w-[300px] md:w-full flex h-[400px] md:h-[85%] items-end  border-x-2 md:border-x-8 border-white " : "w-[129px] md:w-[100%] h-[230px] md:h-[420px]"}`}>
                                    <img src={project.mainImage} 
                                    alt={`preview of ${project.location}`}
                                    className={`object-cover w-full h-full ${currentProjectIndex === index ? "" : "transition-transform duration-300 transform-gpu filter grayscale hover:scale-105 hover:filter-none"}`} />  
                                </div>   
                            
                            </Link>              
                            <div className={`md:w-[35%] my-auto ${currentProjectIndex === index ? "w-[300px] my-auto md:px-4 md:pt-[150px]" : "hidden"}`}>
                                
                                <div className='w-[40px] md:w-1/3 max-sm:inline-flex'>{project.type.animation}</div>
                                <Link to='/technical' className='text-display max-sm:text-[12px]'>
                                    <p className='underline inline-flex align-bottom p-2 md:p-0 md:py-2'>
                                        What is <span className='uppercase'> &nbsp; hydraulic {project.type.name}?</span>
                                    </p>
                                </Link>

                                <p className='text-[12px] md:text-[14px] py-2 md:py-8 max-sm:text-justify'>{project.introText.slice(0,220)}...</p>
                                
                                <div className=''>
                                    <Link
                                    to={`/projects/${project.location}`}
                                    className="link"
                                    onMouseEnter={() => setIsHovered(true)}
                                    onMouseLeave={() => setIsHovered(false)}
                                    >
                                    <div className="grid grid-rows-2 items-center p-4 w-[100px] md:w-[150px] h-[100px] md:h-[150px] bg-[#161616] text-white transition-colors duration-500 transform hover:bg-[#597DAF]">
                                        <p className="text-[12px] md:text-[20px] text-center md:pb-2 md:pt-2 max-sm:pb-2 align-center">
                                        View More
                                        </p>

                                        <img
                                        src={Arrow}
                                        alt="arrow"
                                        className={`m-auto align-center w-1/2 md:w-1/2 ${isHovered ? 'bounce-animation' : ''}`}
                                        />
                                    </div>
                                    </Link>
                                </div>
                            </div>


                        
                        </motion.div>
                        </li>
                    ))}
                    </AnimatePresence>
                </ul>
            </div>

    );
}

export default MainCarousel;
