const desktopImages = [

  require("../assets/images/Battersea.webp"),
  require("../assets/images/Canary_Wharf.webp"),
  require("../assets/images/Rectangle43.webp"),
  require("../assets/images/Rectangle44.webp"),
  require("../assets/images/Rectangle45.webp"),
  require("../assets/images/Rectangle46.webp"),
  require("../assets/images/california.webp"),
  require("../assets/images/closing.webp")
  // add more images as needed
];

export default desktopImages;