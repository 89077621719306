import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import ReCAPTCHA from 'react-google-recaptcha';


const EmailForm = () => {
  const [captchaValue, setCaptchaValue] = useState('');
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICEID,
        process.env.REACT_APP_EMAILJS_TEMPLATEID,
        form.current,
        process.env.REACT_APP_EMAILJS_USERID
      )
      .then(
        (result) => {
          console.log(result.text);
          resetForm();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const resetForm = () => {
    form.current.reset();
  };

  return (
    <form ref={form} onSubmit={sendEmail} className="w-full md:w-[60%] my-auto md:mx-20">
        <div className="mb-4">
            <label className="text-white block mb-2" htmlFor="first_name">
                First Name
            </label>
            <input
            className="w-full px-3 py-2 bg-transparent border-b-2 border-white border-opacity-80 text-gray-400"
            type="text"
            id="first_name"
            name="first_name"
            required
            /></div>
            <div className="mb-4">
                <label className="text-white block mb-2" htmlFor="last_name">
                    Last Name
                </label>
                <input
                className="w-full px-3 py-2 bg-transparent border-b-2 border-white border-opacity-80 text-gray-400"
                type="text"
                id="last_name"
                name="last_name"
                required
                />
            </div>

      <div className="mb-4">
        <label className="text-white block mb-2" htmlFor="email">
          Email
        </label>
        <input
          className="w-full px-3 py-2 bg-transparent border-b-2 border-white border-opacity-80 text-gray-400"
          type="email"
          id="email"
          name="user_email"
          required
        />
      </div>
      <div className="mb-4">
        <label className="text-white block mb-2" htmlFor="contact_number">
          Contact Number
        </label>
        <input
          className="w-full px-3 py-2 bg-transparent border-b-2 border-white border-opacity-80 text-gray-400"
          type="tel"
          id="contact_number"
          name="user_number"
          required
        />
      </div>
      <div className="mb-4">
        <label className="text-white block mb-2" htmlFor="message">
          Message
        </label>
        <textarea
          className="w-full px-3 py-2 bg-transparent border-b-2 border-white border-opacity-80 text-gray-400"
          id="message"
          name="message"
          required
        ></textarea>
      </div>
      <div className='mb-4'>
      <ReCAPTCHA
        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
        onChange={setCaptchaValue}
        />
      </div>
      <div>
        <input
          className="uppercase px-6 py-4 bg-white hover:bg-[#80A3D3] hover:text-white"
          type="submit"
          value="Send"
          disabled={!captchaValue}
        />
      </div>
    </form>
  );
};

export default EmailForm;
