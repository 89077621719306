import { Link } from 'react-router-dom';
import logo_black from '../assets/images/logo_black.webp';
import hydroswingImg from '../assets/images/about-us.webp';
import hollywood from '../assets/images/hollywood-landscape.webp';
import exit from '../assets/images/exit.svg';
import React from 'react';
import { motion } from 'framer-motion';

function AboutUs() {


    return (
        <>
        <motion.div initial={{y: window.innerHeight, opactiy: 1}} animate={{y: 0, opacity: 1, transition: { duration: 0.1}}} exit={{y: window.innerWidth, opactiy: 1, transition: { duration: 0.6}}}>
        <div className='mx-auto'>
            <div className=' p-14'>
                    <Link to='/'>
                        <div className='absolute top-0 left-0 w-2/3 lg:w-[350px] px-8 py-6'>
                            <img className='w-full' src={logo_black} alt="hydroswing logo" />
                        </div>
                    </Link>
                </div>
                <div className='absolute top-0 right-0 w-[100px]'>
                    <Link to='/'>
                        <img src={exit} alt='exit button' className='w-full p-8' />
                    </Link>
                </div>


            <div className=' w-full max-sm:px-8 pb-20 md:pb-20'>
                <h1 className='p-8 text-[60px]'>About Us</h1>
                <div className='md:flex my-auto md:w-full max-md:grid max-md:grid-rows-3'>
                    
                    <p className='md:inline-flex md:w-[27%] md:pl-8 text-justify sm:p-2 max-md:order-1'>THE HYDROSWING Hydraulic door &amp; wall “family” is a
                    set of four different designs all made up of exactly
                    the same components, delivering on Safety, Size,
                    Strength, Style &amp; Sustainability.
                    Whether the Hydroswing is being used as a classic
                    door system or it is the moving wall system carrying
                    the façade design in front of a classic sliding door
                    system, the simplicity of operation and ease of
                    installation sets a new delta in the large door and
                    façade spectrum.
                    Control integration into building management
                    systems, multiple door/wall systems powered from a
                    central hydraulic unit and thermal efficiency exactly
                    as the walls either side….. plus unprecedented
                    security when closed and hydraulically locked in
                    position.</p>
                    {/* <div className='md:hidden mx-auto py-8'>
                        <img src={hydroswingImg} alt='hydroswing single panel on top of building in london' className='w-full mx-auto'/>
                    </div> */}
                    <p className='inline-flex md:w-[27%] md:pl-8 text-justify max-md:order-3'>Hydroswing® has provided many visually stunning
                    solutions for some of the world’s leading architects,
                    with no compromise to safety, functionality,
                    efficiency &amp; style.
                    Coupled with very low “in life” servicing costs and
                    some of the lowest moving part count numbers up to
                    90% less moving parts than prior large door and
                    moving wall systems offered.
                    Over the years, we have invested heavily in quality
                    control systems, engineering and design software
                    allowing us to expand the size and capabilities of our
                    hydraulic door &amp; wall “family”. We can provide door
                    solutions in any size up to 45.72m (150 feet) wide
                    and 12.192m (40 feet) tall. Hydroswing® has several
                    thousand door systems world wide with wind
                    loadings exceeding 320KMH (200MPH).</p>
                
                    <div className='md:w-[46%] flex justify-center max-md:py-8  items-bottom max-md:order-2'>
                        <img src={hydroswingImg} alt='hydroswing single panel on top of building in london' className='md:w-[90%] mx-auto object-contain '/>
                    </div>
                </div>
            </div>

            <div className='md:bg-sec-3-img max-sm:bg-sec-3-img-mob bg-cover bg:center w-full max-sm:px-4 max-sm:py-40 lg:h-[960px] lg:px-10  lg:relative'>
          <div className='max-sm:py-40 lg:py-40'>
            <h3 className='italic text-white pb-6 max-sm:pb-10 font-display lg:w-[950px] leading-snug'>Unequaled in our ﬁeld of hydraulic door and moving wall solutions for architecture and the fa&ccedil;ade industry.</h3>
          <p className='text-base max-sm:text-[20px] text-white'>Hydraulic Bifold, Single Panel, Pivot Panel and Curved Radius Panel.</p>
        </div>
          <div className='text-white lg:w-[500px] lg:text-right text-sm lg:absolute bottom-0 right-0 py-5 lg:p-10'>
            <p className='font-bold'>Project</p>
            <p>Battersea Power Station Re- development</p>
            <p className='font-bold pt-10'>Architect</p>
            <p><a href='https://wilkinsoneyre.com/projects/battersea-power-station'>Wilkinson Eyre</a></p>
            <p className='pt-10 font-bold'>Fa&ccedil;ade Designer/provider</p>
            <p><a href='https://seele.com/references/battersea-power-station-london-uk'>Seele Esse</a></p>
            <p className='pt-10 font-bold'>Main Contractor</p>
            <p><a href='https://www.macegroup.com/projects/battersea-power-station'>Mace Ltd</a></p>
          </div>
         
        </div>

            <div className='py-10 md:py-40'>

                <div className=' md:inline-flex align-text-bottom'>
                    <h2 className='md:w-1/2 text-[32px] md:text-[40px] leading-normal p-8 md:pl-20 my-auto'>Revolutionize Building Design with Cutting-Edge Hydraulic Door and Wall Systems</h2>
                    <img className='md:w-[1000px] h-10md:h-[350px]' src={hollywood} alt='landscape of hollywood project' />

                </div>
                
                <div className='md:grid grid-cols-3 gap-4 p-8 md:p-20 gap-8 text-justify'>
                    <p>The Hydroswing® product has evolved over the years from a basic concept to now an architecturally driven design element. Futuristic & seamless building envelope designs can now using the hydraulic moving wall systems can embody futuristic presence with whole walls opening up, the “inside outside” interaction of buildings is now almost limitless with the Hydroswing Hydraulic Bifold, with fine samples at Battersea Power Station, Canary Wharf in London UK, incorporated by such architects as Heatherwick Studio, Populous, Rafael & Vinnoly , with several specified projects in the works for the future such as the Populous designed CO OP Live Arena in Manchester UK.

                    The Hydroswing hydraulic door & wall family of products were positioned well during & post pandemic to highlight its prowess to “open” & “close” literally any size or building opening for commercial, sporting & residential use.
                    </p>
                    <p className='max-sm:py-8'>We have developed a special hydraulic door system based on our family of doors to facilitate the enhanced evacuation of stadia in an emergency which facilitating control of incoming traffic, a feature which we believe one day all stadia will have as a requirement bringing exit volumes safely up by a significant percentage.

                    Each of our designs is completely bespoke to the opening and operating requirement such as weight and type of façade/cladding required, both inside & out, high wind and blast performance is specified and we design accordingly, finishes of powder coating, both standard and none standard RAL color painting to an extremely high standard along with power/control & operating systems which can be located away from the doors and walls for almost silent operation.
                    </p> 
                    <p>
                    We have door & wall systems in many parts of the world, where it adds value back  to a clients indigenous country we are happy to fabricate and place subcontracts in those countries with many years of experience doing that and we are very happy to ship from our UK base around the world and provide professional install crews and install oversight to whatever the clients' wishes.

                    The answer to our new clients when they ask ……. “Is it possible?” …. Is always YES, The existing & repeat clients simply call us and say “We know you will be able to do this”  and send us the building drawings and façade specifications.!

                    On many levels the Hydroswing hydraulic door & wall family delivers limitless design possibilities, with building design architecture and sustainability now driving the way buildings appear, interact with the environment, humans along with façade technology that if standard doors are used these designs are interrupted and “punctuated” where in reality, the architect and façade designer would prefer 

                    </p>   
                </div>
                
            </div>

            <div>
                <Link to='/projects' className='inline-flex py-10 px-4 md:p-20 h-full w-full transition-color duration-500 bg-black hover:bg-[#597DAF]'>
                    <h2 className='text-white inline my-auto md:pr-2 max-sm:text-[32px] md:pb-4'>See Our Works</h2>
                        <div className=''>
                            <svg width="126" height="40" viewBox="0 0 126 40" fill="none" xmlns="http://www.w3.org/2000/svg" className='w-[132px] h-[40px] inline h-full scale-50 md:scale-80'>
                                <g clipPath="url(#clip0_390_1165)">
                                <path d="M10 20H210" stroke="white" strokeWidth="2"/>
                                </g>
                                <path d="M104 20H116M116 20L104.955 10M116 20L104.955 30" stroke="white" strokeWidth="2"/>
                                <defs>
                                <clipPath id="clip0_390_1165">
                                <rect width="94" height="20" fill="white" transform="translate(10 10)"/>
                                </clipPath>
                                </defs>
                            </svg>
                        </div>
                </Link>
                </div>
        </div>
        </motion.div>
        </>
      );
}

export default AboutUs;