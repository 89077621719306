import React from 'react';
import Lottie from 'lottie-react';
import bifoldAnimation from '../assets/images/animations/bifold.json';
import singleAnimation from '../assets/images/animations/Single-panel.json';
import pivotAnimation from '../assets/images/animations/pivot-animation.json'
const projects = [
  {
    id: '0000',
    location: 'Battersea Power Station',
    city: 'London, UK',
    type: {name: 'Bifold', animation: (<Lottie animationData={bifoldAnimation} loop={true} />)},
    mainImage: require("../assets/images/Battersea_full.webp"),
    additionalImages: [require('../assets/images/Rectangle 24.webp'),
    require('../assets/images/battersea/IMG_2045.webp'),
    require('../assets/images/Rectangle 25.webp')],
    introText: 'The Battersea Power Station redevelopment project, with architects Wilkinson Eyre, is a massive, mixed-use development located in the Nine Elms area of London. The Iconic Power Station & Site has been redeveloped into a vibrant, sustainable, and innovative urban center that provides a range of residential, commercial, and cultural buildings.',
    viewMoreText: ['The development includes over 3,400 new homes, over 116,128 square meters (1.25 million square feet) of office space, a 2,000-seat event space, and a range of cultural and leisure facilities. The development is also designed to be sustainable, with a focus on energy efficiency, green spaces, and renewable energy sources.',
                  'The inclusion in the design on the non waterside main entrances of three Hydroswing hydraulic bifold door systems, carrying CoreTen façade in front of internal, glazed sliding pedestrian door systems, was in keeping with the architectural design goals of “industrial chic” maintaining advanced technology but being sympathetic to the historical industrial architecture which makes Battersea Power station so special.',
                  "In conclusion, the Battersea Power Station redevelopment project is a remarkable example of how to transform an iconic industrial site into a modern, sustainable, and innovative urban center. The project's architectural goals balance respect for history with a forward-thinking approach to design, resulting in a development that is both functional and beautiful."],
    references: {architect: "Wilkinson Eyre", facade: "SEELE ESSE", contractor: "Mace Ltd"}
  },
  {
    id: '0001',
    location: 'Canary Wharf',
    city: 'London, UK',
    type: {name: 'Bifold', animation: (<Lottie animationData={bifoldAnimation} loop={true} />)},
    mainImage: require('../assets/images/canary_wharf/canary_wharf_2.webp'),
    additionalImages: [require('../assets/images/Canary_Wharf.webp'), require('../assets/images/canary_wharf/canary_wharf_3.webp'), require('../assets/images/canary_wharf/canary_wharf_4.jpg')],
    introText: 'Canary Wharf is a vibrant business and commercial district located in East London, overlooking the River Thames. The area has undergone significant redevelopment over the past few decades, transforming it into one of the most modern and dynamic business centres in the world.',
    viewMoreText: ["The redevelopment of Canary Wharf began in the 1980s, with the demolition of the old West India Docks and the creation of a new mixed-use development that would include modern office buildings, shops, restaurants, and leisure facilities. The first office building, One Canada Square, was completed in 1991 and quickly became one of London's most recognizable landmarks.",
                    "Hydroswing were approached by the world leader in façade design, Permasteelisa to provide an aesthetically harmonious solution for an inside outside market on the ground floor of a mixed use office building (Five fully glazed units allowing seamless aesthetics with the surrounding façade whilst providing a dramatic and strong architectural statement when either opened singularly or all at once for the vibrant indoor ground floor market.",
                  "The smooth operating hydraulic pump and motor were remotely located for almost zero mechanical noise when in operation with minimal servicing requirements each year.",
                  "Over the years, Canary Wharf has continued to expand, with new office towers and commercial buildings added to the skyline. The area is now home to many of the world's leading financial and professional services firms, as well as a growing number of tech companies and startups.",
                  "This is perfect example of the Hydroswing hydraulic Bifold door system blurring the lines between wall and door structure for everyones benefit."],
    references: {facade: "Permasteelisa"}
  },
  {
    id: '0002',
    location: 'Tottenham Hotspurs Stadium',
    city: 'London, UK',
    type: {name: 'Bifold', animation: (<Lottie animationData={bifoldAnimation} loop={true}  />)},
    mainImage: require("../assets/images/spurs/spurs.webp"),
    additionalImages: [require("../assets/images/spurs/spurs1.webp"), require("../assets/images/spurs/spurs2.webp"), require("../assets/images/spurs/spurs3.webp")],
    introText: "Tottenham Hotspur's new stadium, also known as the Tottenham Hotspur Stadium, is a state-of-the-art football stadium located in North London. The stadium was completed in 2019 and has a seating capacity of 62,850, making it one of the largest stadiums in the Premier League.",
    viewMoreText: ["The stadium was designed by Populous, the world’s leading stadium & sports facility designer/architect. The stadium features a retractable pitch, which allows for the playing surface to be rotated out of the stadium and exposed to sunlight, promoting healthy grass growth. The stadium also features a 17,500 square meter retractable roof, which can be closed in just 25 minutes.",
                  "In line with the adoption of hydraulic design technology for the retractable pitch system, (SCX Ltd. another leading UK engineering and design company) Hydroswing provided three critical access hydraulic door systems for the direct emergency access to the pitch area for medical and fire teams along with the separate director and owner’s entrance. All three were front and centre and clad in a pre-weathered “grate” cladding/façade design” seen in the images here.",
                  "The design remit was to enable a maximum height for vehicles with seamless operational capabilities with zero loss of aesthetic looks from the outside and maximum security when in the closed position. Hydraulics when in the closed or open and locked position are exceptional resistant to being forced open or closed whilst remaining extremely safe in their operational capability.",
                  "In addition to its impressive engineering features, the Tottenham Hotspur Stadium is also a world-class entertainment venue. The stadium is equipped with a range of bars, restaurants, and hospitality areas, as well as a museum dedicated to the history of Tottenham Hotspur Football Club.",
                  "Overall, the Tottenham Hotspur Stadium is a remarkable achievement in stadium design and engineering. Populous has created a stadium that is not only functional and practical but also visually stunning, making it a must-visit destination for football fans and sports enthusiasts alike."],
    references: {architect: "Populous", facade: "Octatube", contractor: ""}
  },
  {
    id: '0003',
    location: 'The Bird Lanes',
    city: 'Los Angeles, USA',
    type: {name: 'Pivot', animation: (<Lottie animationData={pivotAnimation} loop={true} />)}, 
    mainImage: require('../assets/images/hollywood/main.webp'),
    additionalImages: [
      require('../assets/images/hollywood/hollywood2.webp'),
      require('../assets/images/hollywood/hollywood3.webp'),
      require('../assets/images/hollywood/hollywood4.webp')
    ],
    introText: 'Hollywood in the “Bird Lanes” … Three magnificent Hydroswing Hydraulic Pivot panel hydraulic moving wall/door systems, bringing a one of a kind Mcclean Design (architect) residence, atop of Hollywood, California, to a breathtaking “inside/outside” living experience. The large sections of the main living area are effortlessly, opened and closed with almost no mechanical noise, the power and control systems safely and silently installed in a remote underground control room sending effortless power and motion to the hydraulic cylinders lifting and lowering these huge fully glazed and aesthetically matched moving walls, all at the touch of a button.',
    viewMoreText: [
      'The Hydroswing Pivot Panel was developed for just this kind of installation, where the juxtaposition of the architecture, the location, the view, and the desires of the client to have a unique living experience were all met. ',
      'By Day or By Night, the pivoting hydraulic moving walls are an impressive and unique feature with a WOW ! factor rarely seen, just the very size and strength of the hydraulic pivoting panel carrying state of the art glazing is impressive …. To watch these systems, lift and lower open and close is a thing of architectural wonder.',
      'Whether open for sunset or closed for breakfast as either an unrestricted entry/exit point or just simply a clear view the Hydroswing Pivot Panel delivers on all fronts including strength, ease of use and lowest cost of operation throughout the whole life of the building.'
    ],
    references: {architect: "Mcclean Designs", facade: "Mcclean Designs", contractor: "Gallo Builders"}
  },
  {
    id: '0004',
    location: 'Private Residence',
    city: 'Upstate New York, USA',
    type: {name: 'Single Panel',
           animation: (<Lottie animationData={singleAnimation} loop={true}  />)},
    mainImage: require('../assets/images/rafael_vinnoly/IMG_9022.webp'),
    additionalImages: [
      require('../assets/images/rafael_vinnoly/IMG_9023.webp'),
      require('../assets/images/rafael_vinnoly/IMG_9026.webp'),
    ],
    introText: '007 himself would be proud of this unique residence, commissioned for their very own private use, the renowned NY based architect practice, Rafael & Vinnoly stated “we want a door that doesn’t look like a door, it needs to carry concrete and look like a wall !”',
    viewMoreText: [
      'The result was the use of two Hydroswing Single Panel Hydraulic door systems clad in a concrete panel providing “drive in drive out” access to the semi underground garage structure, with no sacrifice or the design goals and the character of the residence these highly regarded professionals required.',
      'The clean and uninterrupted lines of the two Hydroswing Single Panel hydraulic door systems, is unparalleled with the strength, low part count and reliability with security being to the fore.',
    ],
    references: {architect: "Rafael Vinnoly"}
  },
  {
    id: '0005',
    location: 'Private Car Collector',
    city: 'England, UK',
    type: {name: 'Single Panel', animation: (<Lottie animationData={singleAnimation} loop={true} />)},
    mainImage: require('../assets/images/Car_collection/Hydroswing Hydraulic Bifold (2).webp'),
    additionalImages: [
      require('../assets/images/Car_collection/Hydroswing Hydraulic Bifold (1).webp'),
      require('../assets/images/Car_collection/Hydroswing Hydraulic Bifold (5) (1).webp'),
      require('../assets/images/Car_collection/Hydroswing Hydraulic Bifold (8).webp'),
    ],
    introText: 'How do you make a wall “act like a door” and a “door act like a wall” … when on the outside the door must look identical to the wall within which it sits and when the door must be clad on the inside to match the custom Gladiator Garage Worx Cabinetry …… oh and almost 100% secure when in the closed and locked position.',
    viewMoreText: [
      'Clad in 100% full size brick on the lower percentile and then a Kingspan fully insulated double panel on the higher percentile, fully powder coated with a remote control. When the Hydroswing Single Panel Hydraulic door was designed here it was designed to look when closed as though no access even present. Should the door be attacked in anyway via ram raiding with other method, there is a high degree of certainty this unique door system along with Brick and steel cladding would repel, the worst attack or attempt at theft of the contents of the building.',
      'Simply looking at this side section photo highlights the unique capabilities of the powerful and secure Hydroswing Hydraulic Door Systems.',
      'The exterior of the door system at a glance shows no visible signs where a forced entry could be even attempted. The inside finish in ¼ inch Kick Plate stainless and grey speckled powder coating with “silver finish hydraulic cylinders is a highly attractive but “weapon grade” secure door/wall system with the discerning car collector in mind … providing the security for extremely valuable assets whilst providing a unique automotive garage WOW! Factor for the owner and his friends.'
    ],
    references: {facade: "Kingspan/Client", contractor: "M Hayhurst"}
  },
  {
    id: '0006',
    location: 'Pool House Barn',
    city: 'Wiltshire, UK',
    type: {name: 'Single Panel', animation: (<Lottie animationData={singleAnimation} loop={true} />)},
    mainImage: require('../assets/images/barn_conversion/Hydroswing Hydraulic Bifold (6) (1).webp'),
    additionalImages: [
      require('../assets/images/barn_conversion/Hydroswing Hydraulic Bifold (7) (1).webp'),
      require('../assets/images/barn_conversion/Hydroswing Hydraulic Facade (1).webp')
    ],
    introText: 'Wiltshire is a very pretty and historical county in the United Kingdom. Our design remit here from the architect “when its down and closed it must look just like it’s a barn structure, and when it’s up, the client wants an unhindered view from the inside to the out”',
    viewMoreText: [
      'They say a picture paints a thousand words” …….. The Hydroswing Single Panel Door/wall System delivered 100% on this. When lowered and closed “what swimming pool?” … when up and open, the internal sliding glazed doors can be kept closed on those cold UK says or open when the summer comes. The heat recovery systems are benefitted too with such an insulated door/wall structure in place ……. We always like to tell our potential and existing clients, “the answer is YES on our side ! ….. anything is possible with a Hydroswing Hydraulic Door/Wall System !',
    ],
    references: {}
  }
];

export default projects;