import React from 'react';
import bifoldvid from '../assets/images/Untitled.mp4';
import singlefoldvid from '../assets/images/single-video.mp4';
import pivotfoldvid from '../assets/images/pivot-video.mp4';
import bifoldAnimation from '../assets/images/animations/bifold.json';
import singleAnimation from '../assets/images/animations/Single-panel.json';
import pivotAnimation from '../assets/images/animations/pivot-animation.json';
import Lottie from "lottie-react";


const family = [
  {
    id: 0,
    name: 'Bifold',
    thumbnail: (<Lottie animationData={bifoldAnimation} loop={true} />),
    image: (
      <div className="flex  m-auto bg-transparent w-[80%] h-[60%]">
      <video
        className="w-full mx-auto h-full object-cover"
        src={bifoldvid}
        title="Video"
        autoPlay
        loop
        muted
        controls={false}
        playsInline
        webkit-playsinline="true" // For iOS devices
      ></video>

    </div>),
    text: ['THE HYDROSWING HYDRAULIC BIFOLD is a dual panel hydraulically powered door/wall. The upper panel is in effect the “powered panel via means of two hydraulically powered hydraulic cylinders. ',
            'The door/wall is “pre hung" in its own frame and the static frame is then attached to the building structure. The door/wall system may be installed to new structure or retro fitted where old doors are being removed in favour of the Hydroswing.',
          'Steel, aluminium & even materials such as wood can be used to build the fixed and moving frames with tried and tested zero drop safety cartridge blocks installed on each cylinder should there be an unexpected failure or drop in hydraulic pressure.',
          'The cylinders are powered by the highest quality electric motors which drive high capacity low pressure pumps quietly and efficiently.',
          'Multiple door/walls can be driven from one central pump motor and reservoir with hydraulic hard lines neatly carrying the power to the cylinders on the door/wall.',
          'CE approved and the new BS qualification ready, the Hydroswing is easily incorporated into architectural design and specifications with BIM models available upon request.',
          'The Hydroswing hydraulic Bifold can be clad with almost anything with heavy weight loadings not an issue and the design of the moving and static frames can be harmonious with the façade design and curtain walling which may be chosen, so as to hide the fact there is a door frame present.',
          'The door/wall seals to its own static frame and has incredible thermal properties married to the chosen façade or finish characteristics.'],
  pdf: require('../assets/images/Hydroswing Hydraulic Bi Fold-spec-sheet_mp.pdf'),

    },
    {
        id: 1,
        name: 'Pivot', 
        thumbnail: (<Lottie animationData={pivotAnimation} loop={true} />),
        image: (
          <div className="flex  m-auto bg-transparent w-[80%] h-[60%]">
          <video
            className="w-full mx-auto h-full object-cover"
            src={pivotfoldvid}
            title="Video"
            autoPlay
            loop
            muted
            controls={false}
            playsInline
            webkit-playsinline="true" // For iOS devices
          ></video>
    
        </div>),
        text: ['THE HYDROSWING HYDRAULIC PIVOT PANEL is a Carriage house” style single panel hydraulically powered door/wall. The panel is pivoted over an axle beam inside the static frame as a percentage of above & below ..say 80/20 or 60/40 depending on the aesthetic look required & powered via means of two hydraulically powered hydraulic cylinders.',
              'The door/wall is “pre hung" in its own frame and the static frame is then attached to the building structure. The door/wall system may be installed to new structure or retro fitted where old doors are being removed in favour of the Hydroswing.',
              'Steel, aluminium & even materials such as wood can be used to build the fixed and moving frames with tried and tested zero drop safety cartridge blocks installed on each cylinder should there be an unexpected failure or drop in hydraulic pressure.',
              'The cylinders are powered by the highest quality electric motors which drive high capacity low pressure pumps quietly and efficiently.',
              'Multiple door/walls can be driven from one central pump motor and reservoir with hydraulic hard lines neatly carrying the power to the cylinders on the door/wall.',
              'CE approved and the new BS qualification ready, the Hydroswing is easily incorporated into architectural design and specifications with BIM models available upon request.',
              'The Hydroswing hydraulic pivot door/wall system can be clad with almost anything with heavy weight loadings not an issue and the design of the moving and static frames can be harmonious with the façade design and curtain walling which may be chosen, so as to almost hide the fact there is a door frame present.',
              'This system lends itself very well to very large, glazed openings for residences and stadia structures.',
              'The door/wall seals to its own static frame and has incredible thermal properties married to the chosen façade or finish characteristics.'],
      pdf: require('../assets/images/Hydroswing® Specification Single Panel System USA0224.pdf'),
    },
      {
        id: 3,
        name: 'Single Panel',
        thumbnail: (<Lottie animationData={singleAnimation} loop={true} />),
        image: (
          <div className="flex  m-auto bg-transparent w-[80%] h-[60%]">
          <video
            className="w-full mx-auto h-full object-cover"
            src={singlefoldvid}
            title="Video"
            autoPlay
            loop
            muted
            controls={false}
            playsInline
            webkit-playsinline="true" // For iOS devices
          ></video>
    
        </div>),
        text: ['THE HYDROSWING HYDRAULIC SINGLE PANEL is a top hinged single single panel hydraulically powered door/wall. The moving panel can be as wide as 45 M and as tall as 15 M depending on the aesthetic look required & powered via means of two hydraulically powered hydraulic cylinders. This is the Hydroswing we use for extremely large moving glass structures in stadia and specialized buildings requiring large openings.',
                    'The door/wall is “pre hung" in its own frame and the static frame is then attached to the building structure. The door/wall system may be installed to new structure or retro fitted where old doors are being removed in favor of the Hydroswing.',
                  'Steel, aluminum & even materials such as wood can be used to build the fixed and moving frames with tried and tested zero drop safety cartridge blocks installed on each cylinder should there be an unexpected failure or drop in hydraulic pressure.',
                'The cylinders are powered by the highest quality electric motors which drive high capacity low pressure pumps quietly and efficiently.',
              'Multiple door/walls can be driven from one central pump motor and reservoir with hydraulic hard lines neatly carrying the power to the cylinders on the door/wall.',
              'CE approved and the new BS qualification ready, the Hydroswing is easily incorporated into architectural design and specifications with BIM models available upon request.',
              'The Hydroswing hydraulic single panel  door/wall system  can be clad with almost anything with heavy weight loadings not an issue and the design of the moving and static frames can be harmonious with the façade design and curtain walling which may be chosen, so as to almost hide the fact there is a door frame present.',
              'This system lends itself very well to very large glazed openings for residences and stadia structures.',
              'The door/wall seals to its own static frame and has incredible thermal properties married to the chosen façade or finish characteristics.'
            ],
        pdf: require('../assets/images/Hydroswing® Specification Single Panel System USA0224.pdf'),
        },
        
];

export default family;