import React, { useState, useEffect } from "react";
import desktopImages from "./Images";
import mobileImages from "./MobImages";
import "./Slideshow.css"; // Import CSS file for styling

function Slideshow() {
  const isMobile = window.innerWidth < 641;

  const [currentIndex, setCurrentIndex] = useState(0);

  const images = isMobile ? mobileImages : desktopImages; // Check viewport

  useEffect(() => {
    const timer = setTimeout(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000);
    return () => clearTimeout(timer);
  }, [currentIndex, images.length]); // Add images.length to the dependency array

  return (
    <div className="slideshow-container">
      {images.map((image, index) => (
        <div
          key={index}
          className={`slideshow-image ${
            index === currentIndex ? "active" : ""
          }`}
          style={{ backgroundImage: `url(${image})` }}
        ></div>
      ))}
    </div>
  );
}

export default Slideshow;
