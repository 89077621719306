import React, { useEffect } from 'react';
import { Routes, Route, useLocation, Link } from 'react-router-dom';
import Homepage from './components/Homepage';
import Projects from './components/Projects';
import AboutUs from './components/AboutUs';
import ProjectPage from './components/ProjectPage';
import ContactUs from './components/ContactUs';
import Technical from './components/Technical';
import { AnimatePresence } from 'framer-motion';
import ReactGA from "react-ga4";



const ScrollToTopOnLinkClick = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return null;
};

ReactGA.initialize('G-2G301XWF11');

function TransitionRoutes() {


  const routeTitles = {
    "/": "Homepage",
    "/projects": "Projects Page",
    "/about-us": "About Us Page",
    "/technical": "Technical Details",
    "/contact": "Contact Us Page",
  };
  
  const location = useLocation();
  const currentTitle = routeTitles[location.pathname] || "Unknown Page"; // Default to "Unknown Page" if the pathname doesn't match any keys
  

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname, title: currentTitle });
  }, [location.pathname, currentTitle]);
  
  

  return (
    <AnimatePresence>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/technical" element={<Technical />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/projects/:projectId" element={<ProjectPage />} />
      </Routes>
      <ScrollToTopOnLinkClick /> {/* Move this line inside the TransitionRoutes component */}
      <div className='text-xs'><Link to='https://www.plantec.io/'>Designed by Plantec</Link></div>
    </AnimatePresence>
    
  );
}

export default TransitionRoutes;
